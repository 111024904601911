/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { registerLocaleData } from '@angular/common';
import localeCsCz from '@angular/common/locales/cs';
import localeSkSk from '@angular/common/locales/sk';
import localeHuHu from '@angular/common/locales/hu';
import localeDeDe from '@angular/common/locales/de';
import localeEsEs from '@angular/common/locales/es';
import localeCsExtra from '@angular/common/locales/extra/cs';
import localeSkExtra from '@angular/common/locales/extra/sk';
import localeHuExtra from '@angular/common/locales/extra/hu';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEsExtra from '@angular/common/locales/extra/es';

registerLocaleData(localeSkSk, "sk-SK", localeSkExtra);
registerLocaleData(localeHuHu, "hu-HU", localeHuExtra);
registerLocaleData(localeDeDe, "de-DE", localeDeExtra);
registerLocaleData(localeEsEs, "es-ES", localeEsExtra);
registerLocaleData(localeCsCz, "cs-CZ", localeCsExtra);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
.catch((error) => console.error(error));