import {Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { from, Observable, throwError} from "rxjs";
import { catchError} from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RestService } from "../services/rest.service";

@Injectable()
export class HttpErrorHandler implements HttpInterceptor{

    constructor(private _snackBar: MatSnackBar, private _rest: RestService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url.indexOf("/connect/token") !== -1){
            return next.handle(req);
        } else{
            return from(this.Auth(req,next));
        }
    }

    Auth = async(req: HttpRequest<any>, next: HttpHandler) =>{
        var r = await this._rest.ReturnAccessToken().toPromise();

        let clone = req.clone({
            headers: req.headers.set("Authorization",`Bearer ${r.access_token}`)
        })
        
        return next.handle(clone).pipe(
            catchError((error) => {
                this._snackBar.open(error.message,$localize`Zavřít`,{
                    panelClass:"bg-danger",
                    duration: 3000
                });
                return throwError(error);
            })).toPromise();
    }

}