import { Routes } from '@angular/router';
import { PagesComponent } from '../pages/pages.component';
import { NotFoundComponent } from '../pages/notfound/notfound.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        loadChildren: () => import('../pages/pages.module').then(m=>m.PagesModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];
